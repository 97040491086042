import classes from "./Booking.module.css";

const Sharing = (props) => {

  const sharingHandler = (event) => {
    props.sharingHandler(event.target.value);
  };

  return (
    <>
      <p>
        <small>Sharing Options</small>
      </p>
      <ul className={classes.sharingCheckbox}>
        <li>
          <input
            type="radio"
            
            onChange={sharingHandler}
            value="1"
            name="sharing"
            id="Indivisual"
          />
          <label htmlFor="Indivisual">Indivisual</label>
        </li>
        <li>
          <input
            type="radio"
            
            onChange={sharingHandler}
            value="2"
            name="sharing"
            id="Two_Sharing"
          />
          <label htmlFor="Two_Sharing">Two Sharing</label>
        </li>
        <li>
          <input
            type="radio"
            
            onChange={sharingHandler}
            value="3"
            name="sharing"
            id="Three_Sharing"
          />
          <label htmlFor="Three_Sharing">Three Sharing</label>
        </li>
        {props.choosenVehcle == 2 && (
          <li>
            <input
              type="radio"
              
              onChange={sharingHandler}
              value="4"
              name="sharing"
              id="Four_Sharing"
            />
            <label htmlFor="Four_Sharing">Four Sharing</label>
          </li>
        )}
      </ul>
    </>
  );
};
export default Sharing;

import "./App.css";
import Header from "./components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import BookingForm from "./components/BookingForm/BookingForm";
import Login from "./components/Login/Login";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useEffect, useState } from "react";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import Cookies from "universal-cookie";
import Location from "./components/Location/Location";


function App() {
  const [progress, setProgress] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);


  const progressHandle = (props) => {
    setProgress(props);
  };

  const cookies = new Cookies();

  var accesskey = cookies.get("accesskey");

  const changeLoginState = (event) => {
    setLoggedIn(event);
  };

  

  return (
    <div className="App">
      <LoadingBar
        color="lime"
        progress={progress}
        height={3}
        onLoaderFinished={() => setProgress(0)}
      />

      <section className="mainSection">
        <div className="bookingForm">

          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header
                      accesskey={accesskey}
                      loggedIn={loggedIn}

                    />
                    <BookingForm progressHandle={progressHandle} />
                    
                  </>
                }
              />
              <Route
                path="/login"
                element={
                  <Login
                    changeLoginState={changeLoginState}
                    progressHandle={progressHandle}
                  />
                }
              />

              <Route
                path="/user-info"
                element={<PersonalInfo progressHandle={progressHandle} />}
              />


              {/* <Route
                path="/location"
                element={<Home />}
              /> */}



            </Routes>
          </BrowserRouter>
        </div>
        <div className="staticImage"></div>
      </section>
    </div>
  );
}

export default App;

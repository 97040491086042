import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import Sidebar from "../Layout/Sidebar";

const Header = (props) => {

  const [loggedIn,setLoggedIn] = useState((props.accesskey || props.loggedIn) ? true : false);
  const [sidebar, setSidebar] = useState(false);


  const cookies = new Cookies();
  const navigate = useNavigate();

  const logoutHandler = () => {
    cookies.remove('accesskey');
    setLoggedIn(false);
    navigate('/login');
  }



  const changeSidebar = () => {
    setSidebar((sidebar) => !sidebar);
  };


  return (
    <>
    <Sidebar classChange={sidebar} changeSidebar={changeSidebar} />
    <div className="container-fluid">
      <div className="row pt-2">
        <div className="col-md-4 col-4 text-start">
          <i className="fa fa-bars" onClick={changeSidebar}></i>
        </div>
        <div className="col-md-4 col-4 text-center">Fare Share</div>

        <div className="col-md-4 col-4 text-end">
          {!loggedIn ? (
            <Link to="/login">Login</Link>
          ) : (
            <div onClick={logoutHandler}>Logout</div>
          )}
        </div>
      </div>
    </div>
    </>
    
  );
};
export default Header;

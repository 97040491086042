import classes from './UI.css';

const Error = (props) => {

    const hideAlert = () => {
        props.hideAlert();
    }

    return (
        <div className="alert-container">
            <div className="alert">
                <div className="inner">
                    <div className="title">Error!</div>
                    <div className="text">{props.errorMessage}</div></div>
                <div className="button" onClick={hideAlert}>OK</div>
            </div>
        </div>

    )
}
export default Error;
import classes from "./Booking.module.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { useEffect, useState } from "react";
import Vehicle from "./Vehicle";
import Sharing from "./Sharing";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./../../config";
import Cookies from "universal-cookie";
import Error from "../UI/Error";

const BookingForm = (props) => {

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [vehcle, setVehcle] = useState();
  const [sharing, setSharing] = useState();
  const [addressPickup, setAddressPickup] = useState("");
  const [addressDrop, setAddressDrop] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [coordinatesDrop, setCoordinatesDrop] = useState({
    lat: null,
    lng: null,
  });

  const [pickupTime, setPickupTime] = useState();
  const [dropTime, setDropTime] = useState();
  const [bookingInfo, setBookingInfo] = useState();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSelect = async (address) => {
    props.progressHandle(10);
    const result = await geocodeByAddress(address);
    props.progressHandle(40);
    const coordinatesResult = await getLatLng(result[0]);
    props.progressHandle(60);
    setAddressPickup(address);
    setCoordinates(coordinatesResult);
    props.progressHandle(100);
  };

  const handleSelectDrop = async (address) => {
    props.progressHandle(10);
    const result = await geocodeByAddress(address);
    props.progressHandle(40);
    const coordinatesResult = await getLatLng(result[0]);
    props.progressHandle(60);
    setAddressDrop(address);
    setCoordinatesDrop(coordinatesResult);
    props.progressHandle(100);
  };

  const vehcleChoise = (event) => {
    setVehcle(event);
  };

  const sharingHandler = (event) => {
    setSharing(event);
  };

  const cookies = new Cookies();
  var accesskey = cookies.get("accesskey");

  const submitHandler = (event) => {

    event.preventDefault();

    if (!vehcle) {
      setErrorMessage('Please Select Vehcle');
      setError(true);
      return false;
    }
    if (!sharing) {
      setErrorMessage('Please Select Sharing Option');
      setError(true);
      return false;
    }

    props.progressHandle(10);



    const data = {
      vehcle,
      sharing,
      addressPickup,
      addressDrop,
      coordinates,
      coordinatesDrop,
      pickupTime,
      dropTime,
      userid: accesskey,
    };

    axios({
      method: "post",
      url: `${global.config.base_url}save_booking`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        if (response.status === 200) {
          // document.getElementById("booking-form").reset();
          navigate("/login", { state: { id: response.data.data } });

          props.progressHandle(100);
        }
      })
      .catch(function (error) {
        console.log(error);

        props.progressHandle(100);
      });
  };

  useEffect(() => {
    setLoading(true);

    if (accesskey) {
      props.progressHandle(10);

      const data = {
        userid: accesskey,
      };

      axios({
        method: "post",
        url: `${global.config.base_url}getBookingInfo`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.data.data.booking_info != 0) {
            setBookingInfo(response.data.data);
          }
          props.progressHandle(100);

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          props.progressHandle(100);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);


  const hideAlert = () => {

    setError(false);
  }

  return (
    <div className="container-fluid mt-5 relative-container">
      {error && <Error hideAlert={hideAlert} errorMessage={errorMessage} />}
      {bookingInfo ? (
        <div className={`${classes.bookingDetails} mb-3 card p-3`}>
          <h6>Locations:</h6>
          <p>
            <small>
              <b>From: </b> {bookingInfo.addressPickup}
            </small>
          </p>
          <p>
            <small>
              <b>TO: </b> {bookingInfo.addressDrop}
            </small>
          </p>

          <hr />
          <h6>Times:</h6>
          <div className="row">
            <div className="col-md-6 col-6">
              <p>
                <small>
                  <b>Morning Pickup: </b> {bookingInfo.pickupTime}
                </small>
              </p>
            </div>
            <div className="col-md-6 col-6">
              <p>
                <small>
                  <b>Evening Pickup: </b> {bookingInfo.dropTime}
                </small>
              </p>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-md-6 col-6">
              <h6>Vehicle:</h6>
              <p>
                <small>
                  <b>Vehicle: </b> {bookingInfo.vehcle == 1 ? "AUTO" : "CAB"}
                </small>
              </p>
            </div>
            <div className="col-md-6 col-6">
              <h6>Sharing:</h6>
              <p>
                <small>
                  <b>Sharing: </b>{" "}
                  {bookingInfo.sharing == 1
                    ? "Indivisual"
                    : bookingInfo.sharing == 2
                      ? "Two Sharing"
                      : bookingInfo.sharing == 3
                        ? "Three Sharing"
                        : "Four Sharing"}
                </small>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          {loading && (
            <div className="loader-bg">
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          <form onSubmit={submitHandler} id="booking-form">
            <div className="form-input">
              <div className={`form-group ${classes.formGroup}`}>
                <span>FROM</span>

                <PlacesAutocomplete
                  value={addressPickup}
                  onChange={setAddressPickup}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <input
                        required
                        value={addressPickup}
                        {...getInputProps({
                          placeholder: "Pickup Location",
                          className: "location-search-input form-control",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#cddc39", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <p>{suggestion.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className={`form-group ${classes.formGroup}`}>
                <span>TO</span>

                <PlacesAutocomplete
                  value={addressDrop}
                  onChange={setAddressDrop}
                  onSelect={handleSelectDrop}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <input
                        required
                        value={addressDrop}
                        {...getInputProps({
                          placeholder: "Drop Location",
                          className: "location-search-input form-control",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#cddc39", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              key={suggestion.description}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <p>{suggestion.description}</p>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>

            <div className={`row mb-3 ${classes.timeContainer}`}>
              <div className="col">
                <div className="form-group">
                  <label>Morning Pickup Time</label>
                  <input
                    type="time"
                    onChange={(e) => setPickupTime(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Evening Pickup Time</label>
                  <input
                    type="time"
                    onChange={(e) => setDropTime(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <Vehicle vehcleChoise={vehcleChoise} />
            <Sharing choosenVehcle={vehcle} sharingHandler={sharingHandler} />
            <div className="mt-4">
              <button className="w-100 btn btn-dark">Next</button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
export default BookingForm;

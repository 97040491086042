import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import classes from "./PersonalInfo.module.css";
import './../../config';

const PersonalInfo = (props) => {
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [disability, setDisability] = useState(0);
  const [weekoffsSat, setWeekoffsSat] = useState(0);
  const [weekoffsSun, setWeekoffsSun] = useState(0);

  const [email, setEmail] = useState('');
  const [emergency_contact, setEmergency_contact] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);


  const cookies = new Cookies();

  const navigate = useNavigate();
  

  useEffect(() => {
    var accesskey = cookies.get("accesskey");
    if (!accesskey) {
      navigate("/login");
    }
  }, []);

  const userid = cookies.get("accesskey");

  const userHandler = (event) => {
    event.preventDefault();

    props.progressHandle(20);

    var data = {
      name,
      gender,
      disability,
      weekoffsSat,
      weekoffsSun,
      email,
      emergency_contact,
      userid: userid,
    };

    axios({
      method: "post",
      url: `${global.config.base_url}save_user`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        props.progressHandle(100);
        
        setFieldDisabled(true);

      })
      .catch(function (error) {
        console.log(error);
      });
  };


  useEffect(() => {
    props.progressHandle(20);
    axios({
      method: "post",
      url: `${global.config.base_url}getProfile`,
      data: { userid: userid },
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {   
        
        
        if(response.data.status === true){
          props.progressHandle(50);
            setName(response.data.data.name);
            setGender(response.data.data.gender);
            setDisability(response.data.data.disability);

            setWeekoffsSat(response.data.data.weekoffsSat);
            setWeekoffsSun(response.data.data.weekoffsSun);
            
            setEmail(response.data.data.email);
            setEmergency_contact(response.data.data.emergency_contact);
            setFieldDisabled(true);
            props.progressHandle(100);
        }else{
          props.progressHandle(100);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const updateProfile = () => {
    props.progressHandle(50);
    setFieldDisabled(prevCheck => !prevCheck);
    props.progressHandle(100);

  }

  const setSaturday = () => {
    setWeekoffsSat(weekoffsSat => weekoffsSat === 1 ? 0 : 1)
  }

  const setSunday = () => {
    setWeekoffsSun(weekoffsSun => weekoffsSun === 1 ? 0 : 1)
  }

  

  return (
    <div className={classes.userDetails}>
      <div className="container">
        <div className="navigationComman">
          <i
            className={`fa fa-solid fa-arrow-left ${classes.backToHome}`}
            onClick={() => navigate(-1)}
          ></i>
          <h6>Profile Update</h6>

          <h6 onClick={updateProfile} className={classes.editbutton}>Edit</h6>
        </div>

        <form onSubmit={userHandler}>
          <div className="col-auto mb-3">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-user"></i>
              </div>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`form-control`}
                disabled={fieldDisabled}
              />
            </div>
          </div>

          <div className="col-auto mb-3">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-solid fa-venus-mars"></i>
              </div>

              <select
                className="form-control"
                onChange={(e) => setGender(e.target.value)}
                disabled={fieldDisabled}
                value={gender}
              >
                <option value="" hidden>
                  Select Gender
                </option>
                <option value="M">Male</option>
                <option value="F">Female</option>
                <option value="O">Other</option>
              </select>
            </div>
          </div>

          <div className="col-auto mb-3">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-solid fa-wheelchair"></i>
              </div>

              <select
                className="form-control"
                onChange={(e) => setDisability(e.target.value)}
                disabled={fieldDisabled}
                value={disability}
              >
                <option value="" hidden>
                  Any Disability
                </option>
                <option value="1">Yes</option>
                <option value="0">
                  No
                </option>
              </select>
            </div>
          </div>

          <div className={`form-group mb-3 ${classes.weekoff}`}>
            <p className="mb-1 text-danger">
              <small>Week Off</small>
            </p>
            <input
              type="checkbox"
              placeholder="Week Offs"
              
              onChange={setSaturday}
              
              id="saturday"
              checked={weekoffsSat == '1' ? true : false}
              disabled={fieldDisabled}
            />
            <label htmlFor="saturday">Saturday</label>
            <input
              type="checkbox"
              placeholder="Week Offs"
              
              onChange={setSunday}

              
              id="sunday"
              checked={weekoffsSun == '1' ? true : false}
              disabled={fieldDisabled}
            />
            <label htmlFor="sunday">Sunday</label>
          </div>

          <div className="col-auto mb-3">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-envelope"></i>
              </div>
              <input
                type="email"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
                className={`form-control`}
                value={email}
                disabled={fieldDisabled}
              />
            </div>
          </div>

          <div className="col-auto mb-3">
            <div className="input-group">
              <div className="input-group-text">
                <i className="fa fa-phone"></i>
              </div>
              <input
                type="text"
                placeholder="Emergency Contact Number"
                onChange={(e) => setEmergency_contact(e.target.value)}
                className={`form-control`}
                value={emergency_contact}
                disabled={fieldDisabled}
              />
            </div>
          </div>

          <button className="btn btn-dark w-100" disabled={fieldDisabled}>UPDATE</button>
        </form>
      </div>
    </div>
  );
};
export default PersonalInfo;

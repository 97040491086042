import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Layout.module.css";

const Sidebar = (props) => {
  
  const openSidebar = () => {
    props.changeSidebar();
  };

  const divRef = useRef(null);

  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (divRef.current && !divRef.current.contains(event.target)) {
  //       openSidebar();
  //     }
  //   }

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [divRef]);
  

  return (
    <div className={`sliderBackdrop ${classes.sidebar} ${props.classChange ? 'open' : ''}`} >
      <div className={`slider ${props.classChange ? 'open' : ''} ${classes.sidebarInner}`} ref={divRef}>
        <p>
          <i className="fa fa-times" onClick={openSidebar}></i>
        </p>
        <ul>
          <li><Link to="/user-info">Manage Profile</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

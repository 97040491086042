import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { authentication } from "../../firebase";
import classes from "./Login.module.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import axios from "axios";
import Cookies from "universal-cookie";
import './../../config';

const Login = (props) => {
  const [validPhone, setValidPhone] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState();
  const [otp, setOtp] = useState();
  const [showOtp, setShowOtp] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    var accesskey = cookies.get("accesskey");
    if (accesskey) {
      navigate("/");
    }
  }, []);

  function phonenumber(inputtxt) {
    var phoneno = /^\d{10}$/;
    if (inputtxt.target.value.match(phoneno)) {
      setValidPhone(false);
      setPhoneNumber(inputtxt.target.value);
    } else {
      setValidPhone(true);
    }
  }

  const otphandler = (event) => {
    setOtp(event.target.value);
  };

  useEffect(() => {
    props.progressHandle(10);
    setTimeout(() => {
      props.progressHandle(100);
    }, 100);
  }, []);

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      authentication
    );

    props.progressHandle(50);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    props.progressHandle(10);

    var phonenumberInput = "+91" + phoneNumber;

    setUpRecaptcha();

    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentication, phonenumberInput, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOtp(true);

        props.progressHandle(100);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyOTP = (e) => {
    e.preventDefault();

    props.progressHandle(10);

    let confirmationResult = window.confirmationResult;

    confirmationResult
      .confirm(otp)
      .then((result) => {
        props.progressHandle(30);

        // User signed in successfully.
        const user = result.user;

        // console.log(user);
        // return;

        cookies.set("accesskey", user.uid, { path: "/", secure: true });

        props.progressHandle(50);

        if (location.state !== null) {
          var data = {
            userid: user.uid,
            mobile: user.phoneNumber,
            bookingid: location.state.id,
          };


          axios({
            method: "post",
            url: `${global.config.base_url}updateUserID/`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })

            .then(function (response) {

              if (response.status === 200) {
                props.progressHandle(100);

                navigate("/user-info");
              }
            })
            .catch(function (error) {
              console.log(error);
              props.progressHandle(100);
            });
        } else {

          var data = {
            userid: user.uid,
            mobile: user.phoneNumber,
            
          };
          
          axios({
            method: "post",
            url: `${global.config.base_url}updateUserID`,
            data: data,
            headers: { "Content-Type": "multipart/form-data" },
          })

            .then(function (response) {


              if (response.status === 200) {

                changeLoginState(true);

                props.progressHandle(100);

                navigate("/user-info");
              }
            })
            .catch(function (error) {
              console.log(error);
              props.progressHandle(100);
            });

          

          
        }
      })
      .catch((error) => {
        console.log(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  const changeLoginState = (event) => {
    props.changeLoginState(event);
  };

  return (
    <div className={classes.loginContainer}>
      <i
        className={`fa fa-solid fa-arrow-left ${classes.backToHome}`}
        onClick={() => navigate(-1)}
      ></i>
      <p>Fare Share</p>
      <h6>Enter your mobile number</h6>
      <p>
        <small>A 6-digit OTP will be sent on SMS</small>
      </p>

      <form onSubmit={otp ? verifyOTP : submitHandler}>
        <div className="input-group mb-2 mr-sm-2">
          <div className="input-group-prepend">
            <div className="input-group-text">+91</div>
          </div>
          <input
            type="text"
            className="form-control"
            id="inlineFormInputGroupUsername2"
            autoFocus={true}
            onChange={phonenumber}
            maxLength="10"
          />
        </div>

        <div id="recaptcha-container"></div>

        <div className={`input-group mb-2 mr-sm-2 ${!showOtp && "d-none"}`}>
          <div className="input-group-prepend">
            <div className="input-group-text">OTP</div>
          </div>
          <input
            type="text"
            className="form-control"
            id="inlineFormInputGroupUsername3"
            onChange={otphandler}
            maxLength="6"
          />
        </div>

        <div className="input-group mt-3">
          <button className="btn btn-dark w-100" disabled={validPhone}>
            NEXT
          </button>
        </div>
      </form>
    </div>
  );
};
export default Login;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC31KEmQGRFoJx54EtaitiI3NZ6Ad847uQ",
  authDomain: "fareshare-3b03d.firebaseapp.com",
  databaseURL: "https://fareshare-3b03d-default-rtdb.firebaseio.com",
  projectId: "fareshare-3b03d",
  storageBucket: "fareshare-3b03d.appspot.com",
  messagingSenderId: "553580062703",
  appId: "1:553580062703:web:52a7d90cc040cc294a381a",
  measurementId: "G-YRYDNXZDJZ"
}; 

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);

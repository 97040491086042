import classes from "./Booking.module.css";

const Vehicle = (props) => {

    const vehcleHandler = (event) => {
        props.vehcleChoise(event.target.value);
    }

  return (
    <>
      <p>
        <small>Available Vehicles</small>
      </p>
      <ul className={classes.vehcleUL}>
        <input type="radio"  onChange={vehcleHandler} name="vehcle" value="1" id="auto" />

        <label htmlFor="auto">
          <li className="">
            <img src="/auto.png" className="img-fluid" width={50} />
            <div>
              <h6>Auto</h6>
              <p>
                <small>Get an auto at your doorsteps</small>
              </p>
            </div>
          </li>
        </label>
        <input type="radio"  onChange={vehcleHandler} name="vehcle" value="2" id="cab" />
        <label htmlFor="cab">
          <li className="">
            <img src="/cab.png" className="img-fluid" width={50} />
            <div>
              <h6>Cab</h6>
              <p>
                <small>Get an Cab at your doorsteps</small>
              </p>
            </div>
          </li>
        </label>
      </ul>
    </>
  );
};
export default Vehicle;
